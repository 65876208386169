<template>
  <v-card class="mb-6 pa-2">
    <span v-if="items.length === 0">{{ noDataText }}</span>
    <v-data-table
      v-show="items.length"
      :headers="headers"
      :items="items"
      dense
      class="ma-3 elevation-1"
    >
      <template v-slot:item.title="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <a :href="item.url" target="_blank">{{
                truncate(item.title, 100)
              }}</a>
            </span>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.date="{ item }">
        <span>{{ getDate(item.date) }}</span>
      </template>
      <template v-slot:item.time="{ item }">
        <span>{{ getTime(item.date) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { truncate } from "@/utils/formatting";
import {
  getStrDateFromDateStr as getDate,
  getStrTimeFromDateStr as getTime,
} from "@/utils/dates";
import { formatPercent } from "@/utils/formatting";
import { formatNumber } from "@/utils/formatting";
import { noDataText } from "@/utils/constants";
import axios from "@axios";
import debounce from "lodash/debounce";

export default {
  name: "SessionsTopDiscoverPostsTable",
  data() {
    return {
      noDataText,
      headers: [
        { text: "Titre", value: "title" },
        { text: "Date", value: "date" },
        { text: "Heure", value: "time" },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    formatPercent,
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {
        start_date: this.dates[0],
        end_date: this.dates[1],
        sites: this.sites.join(","),
      };
      const { data } = await axios.get("/rmra-events/republishing-stats", {
        params,
      });
      this.items = data.items;
    },
    truncate(str, n) {
      return truncate(str, n);
    },
    getDate,
    getTime,
  },
  computed: {
    dates() {
      return this.$store.getters["gd/getDates"];
    },
    sites() {
      return this.$store.getters["gd/getSites"];
    },
  },
  watch: {
    dates() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
  },
};
</script>
<style scoped></style>
