<template>
  <v-card class="mb-6 pa-2">
    <v-row class="ma-3">
      <v-col cols="4">
        <site-filter store="gd" :dependsOnSiteGroups="false" />
      </v-col>
      <v-col cols="4">
        <date-filter store="gd" :range="7"></date-filter>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DateFilter,
    SiteFilter,
  },
  data() {
    return {
      disabled: true,
      loading: true,
      value: 25,
      panel: 0,
    };
  },
  computed: {
    sites() {
      return this.$store.getters["gd/getSites"];
    },
  },
};
</script>

<style scoped></style>
